@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import "react-datepicker/dist/react-datepicker.css";
body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-datepicker {
  border-radius: 0.5rem !important;
  font-family: inherit !important;
  border-color: #e5e7eb !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__header {
  background-color: white !important;
  border-bottom: 1px solid #e5e7eb !important;
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
  padding-top: 0.75rem !important;
}

.react-datepicker__day-names {
  margin-top: 0.5rem !important;
}

.react-datepicker__day-name {
  color: #6b7280 !important;
  font-weight: 500 !important;
}

.react-datepicker__day {
  border-radius: 0.375rem !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range {
  background-color: #191d23 !important;
  color: white !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #4a5869 !important;
  color: white !important;
}

.react-datepicker__day--in-selecting-range {
  background-color: #8a95a3 !important;
  color: white !important;
}

.react-datepicker__close-icon::after {
  background-color: #6b7280 !important;
  font-size: 14px !important;
  color: white !important;
}

.react-datepicker__triangle{
  display: none;
}

@media (max-width: 768px) {
  .date-picker-popper {
    width: 100% !important;
    z-index: 10 !important;
    top: -10px !important;
  }

  .react-datepicker {
    width: 100% !important;
    font-size: 16px !important; /* Prevents iOS zoom */
  }

  .react-datepicker__month-container {
    width: 100% !important;
    float: none !important;
  }

  .react-datepicker__month {
    margin: 0.4rem !important;
  }

  .react-datepicker__day {
    width: 2rem !important;
    line-height: 2rem !important;
    margin: 0.2rem !important;
  }

  /* Hide second month on mobile */
  .react-datepicker__month-container:nth-child(2) {
    display: none !important;
  }
}

/* Keep all other existing styles the same */
.react-datepicker {
  border-radius: 0.5rem !important;
  font-family: inherit !important;
  border-color: #e5e7eb !important;
}

/* ... (rest of the existing styles) ... */

@tailwind base;
@tailwind components;
@tailwind utilities;
