body {
  height: 100vh;
  color: #191D23;
}

#root {
  height: 100%;
  overflow: auto;
}


